.tech-stacks {
  width: 100%;
  height: 100%;
  margin-top: 15px;
  font-size: 25px;
  font-weight: 500;
  cursor: pointer;
  transition: all 1s ease-in-out;
  position: relative;
  z-index: 2;
}

.tech-stacks:hover {
  text-decoration: underline;
}
