.main-descriptions-container {
  width: 100%;
  height: 70%;
  margin-top: 24px;
  padding: 15px 0 45px 20px;
  box-sizing: border-box;
}

.main-descriptions-inner-wrapper {
  width: 90%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.main-descriptions-left-container {
  height: 100%;
  width: 70%;
}

.main-descriptions-right-container {
  height: 100%;
  width: 30%;
}

@media only screen and (max-width: 1400px) {
  .main-descriptions-left-container {
    margin-bottom: 20px;
  }

  .main-descriptions-right-container {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .main-descriptions-inner-wrapper {
    display: flex;
    flex-direction: column;
  }

  .main-descriptions-left-container {
    height: 100%;
    width: 100%;
    margin-bottom: 20px;
  }

  .main-descriptions-right-container {
    height: 100%;
    width: 100%;
    margin-bottom: 20px;
  }
}
