.description-nav-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.description-nav-header {
  width: 100%;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0.25px;
  transition: all 0.3s ease-in-out;
}

.description-nav-items {
  width: fit-content;
  height: fit-content;
  padding: 0 20px;
  margin-top: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.description-nav-item {
  width: fit-content;
  font-size: 25px;
  text-decoration: underline;
  text-underline-offset: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 2;
}

.description-nav-item:hover {
  transform: scale(1.1, 1.2);
  transform-origin: left;
}
