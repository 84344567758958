.main-description-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.main-description-header {
  min-height: 48px;
  width: 100%;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0.25px;
  transition: all 0.3s ease-in-out;
}

.main-description-content {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  margin-top: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.main-description-content > p:first-child {
  margin-bottom: 10px;
}

.description-links {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.description-link {
  text-decoration: underline;
  text-underline-offset: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.description-link:hover {
  color: var(--secondary-light-one);
}

.description-link-dark:hover {
  color: var(--main-dark-five);
}
