.hero-slide {
  width: 100%;
  height: 100%;
  min-height: 300px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  opacity: 1;
  /* position: relative; */
}

.hero-slide-content {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 300px;
  box-sizing: border-box;
  opacity: 1;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-container {
  padding-top: 25px;
  width: 90%;
  max-width: 1134px;
  height: fit-content;
  margin: auto;
}

.hero-slide-header {
  font-size: 30px;
  letter-spacing: 0.75px;
  height: 45px;
  width: 100%;
  color: white;
  font-weight: 600;
}

.hero-slide-description {
  margin-top: 5px;
  font-size: 25px;
  letter-spacing: 0.75px;
  width: 100%;
  height: 100%;
  font-weight: 500;
  color: white;
}

.hero-cover {
  background-color: rgb(41, 40, 40);
  opacity: 0.2;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 8px;
}

.hero-cover-dark {
  opacity: 0.3;
}

.live-link-btn {
  position: absolute;
  bottom: 10px;
  right: 15px;
  cursor: pointer;
  font-size: 30px;
  font-weight: 500;
  z-index: 11;
  color: black;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 600px) {
  .live-link-btn {
    max-width: 115px;
  }
}

.live-link-btn > a:hover {
  color: var(--secondary-light-one);
}

.live-link-btn-dark > a:hover {
  color: var(--main-dark-five);
}
