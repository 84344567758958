.navbar-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 65px;
  margin: 0;
  justify-content: space-between;
}

.navbar-home {
  width: 120px;
  height: 50px;
  margin-top: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.navbar-home > img {
  width: 100%;
  height: 100%;
}

.navbar-links {
  display: flex;
  font-size: 30px;
  width: 200px;
  margin-top: 15px;
  margin-right: 20px;
  justify-content: space-between;
}

.nav-link-light {
  transition: all 0.3s;
  cursor: pointer;
}

.nav-link-light:hover {
  color: var(--secondary-light-four);
  transform: scale(1.3, 1.3);
}

.nav-link-dark {
  transition: all 0.3s;
  cursor: pointer;
}

.nav-link-dark:hover {
  color: var(--main-dark-four);
  transform: scale(1.3, 1.3);
}
