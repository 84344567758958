.main {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;
}

.main-container {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 15px;
  box-sizing: border-box;
}

.main-content-wrapper {
  width: 100vw;
  max-width: 1440px;
  height: fit-content;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  margin: auto;
  display: flex;
  border-radius: 10px;
}

.top-left-border {
  position: absolute;
  top: 0;
  left: 0;
  fill: var(--main-dark-four);
  z-index: -1;
}

.bottom-right-border {
  position: absolute;
  bottom: 0;
  right: 0;
  fill: var(--main-dark-four);
  z-index: -1;
}

.border-dark {
  fill: var(--secondary-light-four);
}

.main-content {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.dark-mode-toggle-container {
  position: absolute;
  left: 15px;
  bottom: 15px;
  width: 50px;
  height: 25px;
}

.website-info {
  position: absolute;
  right: 40px;
  bottom: 40px;
  font-size: 20px;
}

.website-info:hover {
  text-decoration: underline;
}
