.hero-container-wrapper {
  width: 100%;
  height: 100%;
  min-height: 324px;
  position: relative;
  top: 5px;
}

.hero-container {
  height: 100%;
  min-height: 324px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  opacity: 1;
  transition: all 1s ease-in-out;
  animation: fadeIn 1.5s ease-in-out;
  display: flex;
  flex-direction: column;
}

@keyframes fadeIn {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

.hero-slide-container {
  width: 100%;
  height: 100%;
  min-height: 300px;
}

.hero-bars {
  width: 100%;
  max-width: 372px;
  height: 24px;
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
  top: 24px;
  margin-left: auto;
  margin-right: auto;
}

.hero-bar-clickable {
  width: calc((100% - 30px) / 4);
  height: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 12;
}

.hero-bar {
  height: 10px;
  width: 100%;
  background-color: var(--main-dark-five);
  border-radius: 5px;
}

.hero-bar-fill {
  height: 10px;
  background-color: var(--secondary-light-three);
  border-radius: 5px;
}

.dark-bar {
  background-color: var(--secondary-light-five);
}

.dark-bar-fill {
  background-color: var(--main-dark-four);
}
