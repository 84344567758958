.toggle-btn-container {
  width: 50px;
  height: 25px;
  cursor: pointer;
}

.toggle-btn-wrap {
  position: absolute;
  left: 0;
  width: 50px;
  height: 25px;
  background: var(--main-dark-four);
  border-radius: 20px;
}

.wrap-toggled {
  background: var(--secondary-light-four);
}

.toggle {
  position: relative;
  z-index: 1;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  animation: untoggle 0.5s forwards ease-out;
  font-size: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggled {
  animation: toggle 0.5s forwards ease-out;
}

@keyframes toggle {
  0% {
    transform: translate3d(0, 0, 0) rotate(0);
    color: white;
  }
  100% {
    transform: translate3d(25px, 0, 0) rotate(360deg);
  }
}

@keyframes untoggle {
  0% {
    transform: translate3d(25px, 0, 0) rotate(0);
  }
  100% {
    color: white;
    transform: translate3d(0, 0, 0) rotate(360deg);
  }
}
