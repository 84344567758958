/* TODO Add site wide styles */

:root {
  /* dark colors from darkest to lightest */
  --main-dark-one: #012a4a;
  --main-dark-two: #013a63;
  --main-dark-three: #01497c;
  --main-dark-four: #105586;
  --main-dark-five: #2a6f97;

  /* light colors from lightest to darkest */
  --secondary-light-one: #a9d6e5;
  --secondary-light-two: #89c2d9;
  --secondary-light-three: #61a5c2;
  --secondary-light-four: #3a82a1;
  --secondary-light-five: #2c7da0;
}

body {
  overflow: overlay;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  background: var(--main-dark-three);
  opacity: 0.2;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-light-two);
  opacity: 0.5;
}

a {
  text-decoration: none;
  color: inherit;
}

html {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

p {
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
}

.disable-caret {
  user-select: none;
}
